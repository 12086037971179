"use client";
import AccountConfirmationComponent from "@/components/auth/account-confirmation";
import CreateAccountComponent from "@/components/auth/create-account";
import LoginComponent from "@/components/auth/login";
import ResetPasswordComponent from "@/components/auth/reset-password";
import Sheet from "@/components/primitives/sheet";
// import Verification from "@/components/auth/email-link-verification";
import { useRouter, useSearchParams } from "next/navigation";
import React, { createContext } from "react";
// import { verify } from "crypto";
import { useClerk } from "@clerk/nextjs";
import { EmailLinkErrorCode, isEmailLinkError } from "@clerk/nextjs/errors";
import Toast from "@/components/primitives/toast";
import Icon from "@/components/primitives/icon";
import Button from "@/components/primitives/button";
import { useQueryUpdater } from "@/utils/hooks/navigation";

type AuthProviderState =
  | "login"
  | "register"
  | "successful-creation"
  | "forgot-password"
  | null;

export const AuthContext = createContext<any | null>(null);

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const searchParams = useSearchParams();
  const router = useRouter();
  const { createQueryParamString, removeQueryParam } = useQueryUpdater();

  const [auth, setAuth] = React.useState<AuthProviderState>(null);

  const [profileData, setProfileData] = React.useState<any>(null);

  const [continuingRegistration, setContinuingRegistration] =
    React.useState(false);
  const oauthParam = searchParams.get("oauth");

  const authQueryParam = searchParams.get("auth");
  const verifyQueryParam = searchParams.get("verify");
  React.useEffect(() => {
    setAuth(authQueryParam as AuthProviderState);
    console.log("OAUTH", oauthParam);
    if (oauthParam !== null) {
      setAuthState("register");
    }
    if (verifyQueryParam !== null) {
      verify();
    }
  }, []);

  React.useEffect(() => {
    if (oauthParam !== null) {
      setAuthState("register");
    }
  }, [oauthParam]);

  React.useEffect(() => {
    if (typeof window === "undefined" || typeof document === "undefined") return;
    const body = document.body;
    if (auth !== null) {
      console.log("AUTH", auth);
      document.documentElement.setAttribute("data-scroll-locked", "1")
      // document.documentElement.classList.add("must-scroll");
      router.push(createQueryParamString("auth", auth), { scroll: false });
    } else {
      document.documentElement.setAttribute("data-scroll-locked", "0")
      // document.documentElement.classList.remove("must-scroll");
      router.replace(removeQueryParam("auth"), { scroll: false });
    }
  }, [auth, router]);

  function setAuthState(state: AuthProviderState) {
    setAuth(state);
  }

  const [verificationStatus, setVerificationStatus] = React.useState("none");

  const { handleEmailLinkVerification } = useClerk();
  async function verify() {
    try {
      await handleEmailLinkVerification({
        // redirectUrl: "http://localhost:3000?auth=register",
        // redirectUrlComplete: "http://localhost:3000",
      });

      setVerificationStatus("verified");
    } catch (err) {
      // Verification has failed.
      let status = "failed";
      if (isEmailLinkError(err) && err.code === EmailLinkErrorCode.Expired) {
        status = "expired";
      }
      setVerificationStatus(status);
    }
  }

  if (verificationStatus === "verified") {
    // router.replace(removeQueryParam("verify"));
  }

  React.useEffect(() => {
    if (verificationStatus === "verified") {
      if (continuingRegistration) {
        setAuthState("successful-creation");
      }
    }
  }, [verificationStatus]);

  return (
    <AuthContext.Provider
      value={{
        profileData,
        setProfileData,
        setAuth: (state: AuthProviderState) => setAuthState(state),
        closeAuth: () => {
          setAuthState(null);
        },
      }}
    >
      {children}
      <Sheet
        open={auth !== null}
        onClickOverlay={(e) => {
          setAuthState(null);
        }}
      >
        <div className="relative h-full overflow-x-hidden z-[1]">
          <div className="bg-[url('/images/moneyline-drawer.png')] absolute top-0 w-full h-full z-0 pt-10">
            <div className="flex flex-row justify-between gap-4 items-center relative z-[1]">
              <div className="flex basis-3/12"></div>
              <div className="flex basis-7/12">
                <img
                  src="/images/logo.svg"
                  alt="Moneyline Logo"
                  className="w-full"
                />
              </div>
              <div className="flex basis-3/12 justify-center">
                <Button
                  variant="circle"
                  size="sm"
                  color="tertiary"
                  onClick={() => setAuthState(null)}
                >
                  <Icon name="close" className="fill-secondary-300" size="sm" />
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="absolute top-0 left-0 right-0 w-full flex h-[123px] justify-center items-center">
            <img src="/images/logo.svg" alt="Moneyline Logo" width="310.94" />
          </div> */}
          <div className="flex flex-col justify-start bg-white rounded-tl-[32px] rounded-tr-[32px] h-[calc(100%-108px)] mt-[108px] md:h-[calc(100%-123px)] md:mt-[123px] relative">
            <div className="px-8 md:px-12 pt-8 md:pt-12 h-full">
              {auth === "login" ? (
                <LoginComponent />
              ) : auth === "register" ? (
                <CreateAccountComponent />
              ) : (
                auth === "successful-creation" && (
                  <AccountConfirmationComponent />
                )
              )}
            </div>
          </div>
        </div>
      </Sheet>

      {/* <Toast.Content
        open={verificationStatus === "verified" && !continuingRegistration}
        onOpenChange={(open) => {
          !open && setVerificationStatus("none");
        }}
      >
        <div className="flex items-center py-4 px-6 pr-10 gap-4">
          <Icon
            name={"checkmark"}
            className={"fill-success-300 color-success-300"}
            size="lg"
          />
          You have been logged in successfully.
        </div>
      </Toast.Content> */}

      <Toast.Content
        open={verificationStatus === "expired"}
        onOpenChange={(open) => {
          !open && setVerificationStatus("none");
        }}
      >
        <div className="flex items-center py-4 px-6 pr-10 gap-4">
          <Icon
            name={"alert-triangle"}
            className={"fill-error-300 color-error-300"}
            size="lg"
          />
          The verification link you clicked has expired. Please try logging in
          again.
        </div>
      </Toast.Content>

      <Toast.Content
        open={verificationStatus === "failed"}
        onOpenChange={(open) => {
          !open && setVerificationStatus("none");
        }}
      >
        <div className="flex items-center py-4 px-6 pr-10 gap-4">
          <Icon
            name={"alert-triangle"}
            className={"fill-error-300 color-error-300"}
            size="lg"
          />
          Login verification failed. Please try again.
        </div>
      </Toast.Content>
    </AuthContext.Provider>
  );
}
